
// import {sensorsUrl} from '../config/env';
const sensors = require('sa-sdk-javascript');
sensors.init({
  //数据接收地址
  server_url: '',
  //如果神策代码中 `sensorsdata.min.js` 版本是 1.13.1 及以前版本，必须须配置 heatmap_url，高于此版本不需要配置。heatmap_url 神策分析中点击分析及触达分析功能代码，代码生成工具会自动生成。
  // heatmap_url: "https://cdn.jsdelivr.net/npm/sa-sdk-javascript@1.15.1/heatmap.min.js",
  //如果神策后台版本及 `sensorsdata.min.js` 均是 1.10 及以上版本，这个参数不需要配置 web_url。 web_url 神策分析中点击分析及触达分析功能会用到此地址，代码生成工具会自动生成。
  web_url: '神策分析后台地址',
  heatmap: {
    //是否开启点击图，默认为 default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭
    clickmap: 'default',
    //是否开启触达注意力图，默认 not_collect 表示关闭，不会自动采集 $WebStay 事件，可以设置 'default' 表示开启
    scroll_notice_map: 'default',
    collect_element (element_target) {
      // 如果这个元素有属性sensors-disable=true时候，不采集
      if (element_target.getAttribute('sensors-disable') === 'true') {
        return false;
      } 
      return true;
    }
  },
  // 默认值 false，表示是否开启单页面自动采集 $pageview 功能，SDK 会在 url 改变之后自动采集web页面浏览事件 $pageview。
  is_track_single_page: true,
  //Web 端本地打印数据采集日志的方式,没有设置该参数时，默认为 ture
  show_log: false,
  cross_subdomain: false
});
//以异步加载 SDK 为例，神策 SDK 初始化完成，此时调用设置公共属性的方法，来保证之后的事件都有这两个属性。
sensors.registerPage({
  source: 'Web',
  login_user_source: '三门峡'
});
sensors.quick('autoTrack');
export default sensors;
