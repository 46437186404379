// 配置编译环境和线上环境之间的切换

// 二位
const env = process.env;

let gantangzwWebsiteUrl = '';
let gantangzwFAQsUrl = '';
let downloadUrl = '';
let loginUrl = '';
const baseUrl = '';
gantangzwWebsiteUrl = env.VUE_APP_gantangzwWebsiteUrl;
gantangzwFAQsUrl = env.VUE_APP_gantangzwFAQsUrl;
downloadUrl = env.VUE_APP_downloadUrl;
loginUrl = env.VUE_APP_loginUrl;
const sensorsUrl = process.env.VUE_APP_SENSORSURL;
export {
  baseUrl,
  downloadUrl,
  loginUrl,
  gantangzwWebsiteUrl,
  gantangzwFAQsUrl,
  env,
  sensorsUrl
};
