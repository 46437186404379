import Vue from 'vue';
import App from './App.vue';
import router from './router';
import './assets/css/_normalize.scss';
import './plugins/element.js';
import sensors from './sensors/sensors.js';
import * as Sentry from '@sentry/vue';
import {Integrations} from '@sentry/tracing';
Vue.prototype.$sensors = sensors; //神策埋点
Vue.config.productionTip = false;

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    Vue,
    dsn: 'https://8e21edefb5214c5fb5dd72cef8531456@sentry.internal.gantangzw.cn/12',
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['localhost', 'my-site-url.com', /^\//]
      })
    ],
    tracesSampleRate: 1.0
  });
}
Vue.config.errorHandler = function(err, vm) {
  console.error('================vue render error:============= ', err);
  if (process.env.NODE_ENV === 'production') {
    vm.$store.getters.userInfo && Sentry.setUser({id: vm.$store.getters.userInfo.id});
    Sentry.captureException(err);
  }
};

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');
