<template>
  <header height="60px">
    <el-row class="box-container hidden-md-and-down" type="flex" justify="space-between" align="middle">
      <img :src="icon.logo" alt="">
      <ul class="header-nav">
        <li class="active">首页</li>
        <li>
          <a href="#download">下载</a>
        </li>
        <li>
          <a :href="loginUrl" target="_blank">登录</a>
        </li>
      </ul>
    </el-row>
    <el-row class="hidden-md-and-up" type="flex" justify="space-between" align="middle">
      <img :src="icon.logo" alt="">
      <a :href="downloadUrl">立即下载App</a>
    </el-row>
  </header>
</template>

<script>
import {
  downloadUrl,
  loginUrl
} from '@/config/env';
import regionInfo from '@/config/region.config';
export default {
  name: 'Header',
  data() {
    return {
      icon: {
        logo: regionInfo.head.icon
      },
      downloadUrl,
      loginUrl
    };
  },
  created() {
    console.log(regionInfo);
  }
};
</script>

<style lang="scss" scoped>
header {
    background: #C00E16;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 100;

    .box-container {
        height: 100%;
        padding: 0 60px;
        max-width: 1198px;
        margin: 0 auto;

        img {
            height: 28px;
        }

        ul {
            overflow: hidden;
            margin-left: auto;
            color: #fff;
            line-height: 60px;
            font-family: PingFangSC-Regular;
            font-size: 14px;
            color: #FFFFFF;

            li {
                float: left;
                margin-left: 20px;
                cursor: pointer;

                &.active {
                    font-size: 18px;
                    text-shadow: 0 2px 4px rgba(255, 255, 255, 0.50);
                    position: relative;

                    &::after {
                        content: '';
                        background: #FFFFFF;
                        box-shadow: 0 2px 4px 0 rgba(255, 255, 255, 0.50);
                        border-radius: 1px;
                        position: absolute;
                        bottom: 10px;
                        width: 10px;
                        left: 50%;
                        margin-left: -5px;
                        height: 2px;
                    }
                }
            }
        }
    }

    .hidden-md-and-up {
        height: 60px;
        padding: 0 30px;

        img {
            height: 26px;
        }

        a {
            height: 26px;
            padding: 0 20px;
            background: #FFFFFF;
            border-radius: 16px;
            font-family: PingFangSC-Medium;
            font-size: 12px;
            color: #C30E17;
            letter-spacing: 2px;
            text-align: center;
            line-height: 26px;
        }
    }
}
</style>
