<template>
  <main>
    <el-row class="home-banner">
      <el-row class="box-container">
        <el-carousel height="660px" indicator-position="none" arrow="never">
          <el-carousel-item v-for="(item,index) in icons" :key="index">
            <img :src="item.logo" alt="">
          </el-carousel-item>
        </el-carousel>
      </el-row>
    </el-row>
    <el-row class="slogans-lists">
      <el-row class="box-container">
        <el-col
          v-for="(item, index) in slogans"
          :key="index"
          class="slogans-items"
          :xs="24"
          :sm="8"
          :md="8"
        >
          <a :href="'#'+item.target">
            <img :src="item.icon" alt="">
            <div class="slogans-title">{{item.label}}</div>
          </a>
        </el-col>
      </el-row>
    </el-row>
    <el-row class="slogans-details-lists">
      <el-row class="box-container">
        <el-row v-for="(item, index) in slogansDetails" :key="index" class="slogans-details-items">
          <el-row :id="item.id" class="slogans-details-title">{{item.title}}</el-row>
          <el-row class="slogans-details-content">
            <el-col :xs="24" :sm="13" :md="13" :style="{float:index%2===1?'right':'left'}">
              <img :src="item.icon" alt="" srcset="">
            </el-col>
            <el-col :xs="24" :sm="11" :md="11" class="slogans-details-tips">
              <div v-for="tip in item.tips" :key="tip">
                {{tip}}
              </div>
            </el-col>
          </el-row>
        </el-row>
      </el-row>
    </el-row>

    <el-row v-if="isShowNewsLists" class="slogans-details-items news-modules">
      <el-row class="box-container">
        <el-row class="slogans-details-title">新闻动态</el-row>
        <el-row class="news-lists hidden-sm-and-down">
          <el-col v-for="(item, index) in newsLists" :key="index" class="news-items">
            <img :src="item.icon" alt="">
            <div>{{item.label}}</div>
          </el-col>
        </el-row>
        <el-carousel class="news-carousel hidden-md-and-up" :interval="4000" indicator-position="none" arrow="always">
          <el-carousel-item v-for="(item, index) in newsLists" :key="index">
            <img :src="item.icon" alt="">
            <div>{{item.label}}</div>
          </el-carousel-item>
        </el-carousel>
      </el-row>
    </el-row>
  </main>
</template>


<script>
import regionInfo from '@/config/region.config';
export default {
  name: 'Main',
  data() {
    return {
      icons: [{
        logo: regionInfo.main.icons
      }],
      slogans: [{
        icon: regionInfo.main.zdzf_logo,
        label: '最懂政府',
        target: 'zdzf'
      }, {
        icon: regionInfo.main.wsgx_logo,
        label: '务实高效',
        target: 'wsgx'
      }, {
        icon: regionInfo.main.zdxl_logo,
        label: '值得信赖',
        target: 'zdxl'
      }],
      newsLists: [{
        icon: '/img/pic_new_01.jpg',
        label: '市领导前往崤云公司视察研发工作'
      }, {
        icon: '/img/pic_new_02.jpg',
        label: '甘棠政务陕州区试点产品演示会'
      }, {
        icon: '/img/pic_new_03.jpg',
        label: '市政府政务领域专家顾问培训交流'
      }],
      slogansDetails: [{
        id: 'zdzf',
        icon: regionInfo.main.zdzf_detail,
        title: '最懂政府',
        tips: ['针对政府业务层层深挖', '深入了解政务用户实际需求']
      }, {
        id: 'wsgx',
        icon: regionInfo.main.wsgx_detail,
        title: '务实高效',
        tips: ['打破办公时空的限制', '多端协同 及时响应']
      }, {
        id: 'zdxl',
        icon: regionInfo.main.zdxl_detail,
        title: '值得信赖',
        tips: ['数据安全 信息准确', '打造安全可靠的政务办公软件']
      }],
      isShowNewsLists: regionInfo.main.isShowNewsLists
    };
  }
};
</script>

<style lang="scss" scoped>
main {
    width: 100%;
    background-color: #fff;

    .box-container {
        height: 100%;
        max-width: 1198px;
        margin: 0 auto;

        @media screen and (max-width:1198px) {
            width: 100%;
        }
    }

    .home-banner {
        background-image: linear-gradient(to bottom, #D2261C, #9F2122);

        img {
            width: 100%;
        }

        @media screen and (max-width:1198px) {

            /deep/ .el-carousel__container {
                height: 210px !important;
            }
        }
    }

    .slogans-lists {
        flex-wrap: wrap;
        background-color: #fff;
        padding-bottom: 100px;
        padding-top: 90px;

        .slogans-items {
            cursor: pointer;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            img {
                width: 120px;
                margin-bottom: 30px;
            }

            div {
                font-family: STHeitiSC-Medium;
                font-size: 30px;
                color: #333333;
                line-height: 1;
                text-align: center;
            }
        }

        @media screen and (max-width:1198px) {
            padding-top: 10px;
            padding-bottom: 0;

            .slogans-items {
                height: 200px;
                background: #F8F8F8;
                border-radius: 10px;
                margin-bottom: 10px;

                a {
                    display: block;
                    height: 100%;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }

                img {
                    width: 100px;
                    margin-bottom: 30px;
                }

                div {
                    font-family: STHeitiSC-Medium;
                    font-size: 20px;
                    color: #333333;
                    text-align: center;
                    line-height: 20px;
                }

            }

            .box-container {
                padding: 0 20px;
            }
        }
    }

    .slogans-details-items {
        padding: 0 90px 60px 90px;

        @media screen and (max-width:1198px) {
            padding: 0 30px 30px 30px;
        }

        .slogans-details-title {
            padding-top: 80px;
            padding-bottom: 80px;
            width: 300px;
            margin: 0 auto;
            font-family: PingFangSC-Medium;
            font-size: 40px;
            color: #333333;
            letter-spacing: 0;
            text-align: center;
            line-height: 40px;
            position: relative;

            &::after,
            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 50%;
                margin-top: -20px;
                bottom: 0;
                background: url('/img/icon_bar.png') no-repeat;
                background-size: 100%;
                width: 14px;
                height: 40px;
            }

            &::after {
                right: 0;
                left: auto;
            }
        }

        &:nth-child(2n + 1) {
            .slogans-details-tips {
                padding-left: 60px;
                box-sizing: border-box;
            }
        }

        .slogans-details-content {
            height: 370px;

            img {
                height: 100%;
                width: 100%;
                object-fit: scale-down;
            }
        }

        .slogans-details-tips {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;

            div {
                font-family: STHeitiSC-Medium;
                font-size: 28px;
                color: #333333;
                letter-spacing: 2px;
                line-height: 50px;
            }
        }

        &.news-modules {
            background-color: #fff;
            // padding: 0 60px;

            .slogans-details-title {
                padding-top: 100px;
                padding-bottom: 100px;
            }

            @media screen and (max-width:1198px) {
                padding: 0;

                .slogans-details-title {
                    line-height: 20px;
                    padding: 25px 0;
                    font-size: 20px;
                    width: 160px;

                    &::after,
                    &::before {
                        height: 20px;
                        margin-top: -13px;
                        width: 8px;
                    }
                }
            }
        }

        .news-lists {
            padding-bottom: 100px;
            margin-left: -10px;

            .news-items {
                // background-color: red;
                margin-left: 10px;
                width: calc((100% - 30px) / 3);

                img {
                    width: 100%;
                    padding-bottom: 30px;
                }

                div {
                    font-family: STHeitiSC-Medium;
                    font-size: 16px;
                    color: #333333;
                    text-align: center;
                    line-height: 28px;
                    width: 100%;
                    overflow: hidden;
                    height: 28px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    word-break: break-all;
                }
            }
        }

        .news-carousel {
            width: 100%;

            .el-carousel__container {
                min-height: 280px;
            }

            img {
                width: 100%;
            }

            div {
                font-family: STHeitiSC-Medium;
                font-size: 16px;
                color: #333333;
                line-height: 36px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                text-align: center;
            }
        }
    }

    .slogans-details-lists {
        background: #FBFBFB;

        @media screen and (max-width:1198px) {

            .slogans-details-items {
                padding: 0 30px;

                .slogans-details-content {
                    height: auto;
                }

                .slogans-details-tips {
                    padding-left: 0;
                    height: 90px;

                    div {
                        font-family: STHeitiSC-Medium;
                        font-size: 18px;
                        color: #333333;
                        text-align: center;
                        line-height: 24px;
                    }
                }
            }

            .slogans-details-title {
                line-height: 20px;
                padding: 25px 0;
                font-size: 20px;
                width: 160px;

                &::after,
                &::before {
                    height: 20px;
                    margin-top: -13px;
                    width: 8px;
                }
            }
        }
    }
}
</style>
