module.exports = {
  smx: {
    regionName: '甘棠政务官网',
    head: {
      icon: '/img/icon_logo.png'
    },
    main: {
      icons: '/img/pic_01.jpg',
      zdzf_logo: '/img/icon_zdzf.png',
      wsgx_logo: '/img/icon_wsgx.png',
      zdxl_logo: '/img/icon_zdxl.png',
      zdzf_detail: '/img/pic_js_01.png',
      wsgx_detail: '/img/pic_js_02.png',
      zdxl_detail: '/img/pic_js_03.png',
      isShowNewsLists: true
    },
    footer: {
      moreMessage: '甘棠政务网页版登陆入口',
      aboutOurs: '友情链接',
      isShowWanLian: true,
      isShowWeb: true,
      companyCode: '/img/smxxy-code.jpeg',
      companyNumber: '豫ICP备17039311号 版权所有 三门峡崤云信息服务股份有限公司'
    },
    downloadQrPro: '/img/icon_downloadQr_pro.png',
    downloadQrPre: '/img/icon_downloadQr_pre.png'
  },
  zhoukou: {
    regionName: '周口政务官网',
    head: {
      icon: '/img/icon_zhoukou_logo.png'
    },
    main: {
      icons: '/img/pic_01_zhoukou.png',
      zdzf_logo: '/img/icon_zdzf_zhoukou.png',
      wsgx_logo: '/img/icon_wsgx_zhoukou.png',
      zdxl_logo: '/img/icon_zdxl_zhoukou.png',
      zdzf_detail: '/img/pic_js_01_zhoukou.png',
      wsgx_detail: '/img/pic_js_02_zhoukou.png',
      zdxl_detail: '/img/pic_js_03_zhoukou.png',
      isShowNewsLists: false
    },
    footer: {
      moreMessage: '周口政务网页版登陆入口',
      aboutOurs: '关于我们',
      isShowWanLian: false,
      isShowWeb: false,
      companyCode: '/img/smxxy-code.jpeg',
      companyNumber: '豫ICP备2021024334号 版权所属 周口市政务服务和大数据管理局'
    },
    downloadQrPro: '/img/icon_downloadQr.png',
    downloadQrPre: '/img/icon_downloadQr.png'
  }
};
