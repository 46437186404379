<template>
  <footer id="download">
    <!-- www.smx.gantangzw.cn -->
    <el-row class="box-container footer-lists">
      <el-col :xs="24" :sm="8" :md="7" :lg="4" class="footer-items">
        <el-row class="footer-title">
          更多信息
        </el-row>
        <el-row>
          <el-col :xs="12" :sm="24" :md="24">
            <a :href="gantangzwWebsiteUrl" target="_blank" class="footer-links">{{moreMessage}}</a>
          </el-col>
          <el-col :xs="12" :sm="24" :md="24">
            <a :href="gantangzwFAQsUrl" target="_blank" class="footer-links">常见问题手册</a>
          </el-col>
        </el-row>
      </el-col>
      <el-col :xs="24" :sm="8" :md="6" class="footer-items">
        <el-row class="footer-title">
          {{aboutOurs}}
        </el-row>
        <el-col :xs="12" :sm="24" :md="24">
          <a v-if="isShowWeb" :href="gtcompanyUrl" target="_blank" class="footer-links">
            河南甘棠软件科技有限公司
          </a>
          <a v-if="isShowWeb" :href="smxxyCompanyUrl" target="_blank" class="footer-links">
            三门峡崤云信息服务股份有限公司
          </a>
          <a v-if="isShowWeb" :href="netCompanyUrl" target="_blank" class="footer-links">
            万联融通（三门峡）工业互联网平台
          </a>
        </el-col>
        <el-col :xs="12" :sm="24" :md="24"><a :href="companyCode" class="footer-links">微信公众号</a></el-col>
        <el-col :xs="12" :sm="24" :md="24"><img class="footer-code" :src="companyCode" alt=""></el-col>
      </el-col>
      <el-col :xs="24" :sm="8" :md="10" class="footer-items">
        <el-row class="footer-title">
          联系我们
        </el-row>
        <el-row class="footer-address">
          <el-row title="联系地址">河南自贸试验区郑州片区（郑东）创业路9号绿地中心北塔21层2105号</el-row>
          <el-row title="联系地址">河南省三门峡市经济开发区五原西路传媒大厦11楼1104</el-row>
          <el-row title="联系地址">西安市碑林区友谊中路中铁第壹国际A座12</el-row>
          <el-row title="联系电话">电话：<a :href="companyPhone">400-000-5610</a></el-row>
        </el-row>
      </el-col>
      <el-col :xs="24" :sm="24" :md="3" class="footer-items hidden-sm-and-xs">
        <el-row class="footer-title">
          下载方式
        </el-row>
        <el-row class="download-lists">
          <div class="download-items">
            <img :src="env.NODE_ENV ==='production'?icon.downloadQrPro:icon.downloadQrPre" class="download-imgs" alt="">
            <div class="download-tips">
              扫码下载
            </div>
          </div>
        </el-row>
      </el-col>
    </el-row>
    <el-row class="copyright">
      <a :href="companyRecordNumber" target="_blank" rel="noopener noreferrer">{{companyNumber}}</a>
    </el-row>
  </footer>
</template>

<script>
import {
  env,
  gantangzwWebsiteUrl,
  gantangzwFAQsUrl
} from '@/config/env';
import regionInfo from '@/config/region.config';
export default {
  name: 'Footer',
  components: {
  },
  data() {
    return {
      icon: {
        win: '/img/icon_win.png',
        downloadQrPro: regionInfo.downloadQrPro,
        downloadQrPre: regionInfo.downloadQrPre,
        down: '/img/icon_down.png'
      },
      moreMessage: regionInfo.footer.moreMessage,
      isShowWanLian: regionInfo.footer.isShowWanLian,
      isShowWeb: regionInfo.footer.isShowWeb,
      aboutOurs: regionInfo.footer.aboutOurs,
      env,
      gantangzwWebsiteUrl,
      gantangzwFAQsUrl,
      smxxyCompanyUrl: 'http://www.smxxy.cn/',
      gtcompanyUrl: 'https://www.gantangtech.cn/',
      netCompanyUrl: 'http://www.smxgyhlw.com/',
      companyCode: regionInfo.footer.companyCode,
      xaCompanyPhone: 'tel:+029-81711952',
      companyPhone: 'tel:+0398-2168912',
      companyRecordNumber: 'http://beian.miit.gov.cn',
      wanLianRongTongUrl: 'http://www.smxgyhlw.com/',
      companyNumber: regionInfo.footer.companyNumber
    };
  }
};
</script>

<style lang="scss" scoped>
footer {
    background: #333333;

    .box-container {
        height: 100%;
        padding: 40px 20px 0;
        max-width: 1198px;
        margin: 0 auto;

        @media screen and (max-width:1198px) {
            width: 100%;
            padding: 30px 20px 0;
        }
    }

    .footer-lists {
        .footer-items {
            margin-bottom: 40px;
            padding-right: 10px;
            .footer-code {
                display: block;
                width: 120px;
                height: 120px;
                margin-top: 10px;
                object-fit: scale-down;
            }

            .footer-title {
                font-family: STHeitiSC-Medium;
                font-size: 24px;
                color: #FFFFFF;
                line-height: 24px;
                margin-bottom: 30px;
            }

            .footer-links {
                display: block;
                font-family: STHeitiSC-Medium;
                font-size: 16px;
                color: #ADADAD;
                line-height: 36px;
            }

            .footer-address {
                .el-row {
                    font-family: STHeitiSC-Medium;
                    font-size: 16px;
                    color: #ADADAD;
                    line-height: 36px;
                    cursor: pointer;
                }
            }

            .download-lists {

                >.download-items {
                    border-radius: 5px;
                    text-align: center;
                    float: left;
                    box-sizing: border-box;

                    img.download-imgs {
                      display: inline-block;
                      width: 100px;
                      height: 100px;
                      object-fit: scale-down;
                      padding: 8px;
                      background-color: #fff;
                    }

                    div.download-tips {
                        margin-top: 10px;
                        display: block;
                        color: #ADADAD;
                    }

                }
            }
        }
    }
}

.copyright {
    background: #3B3B3B;
    font-family: STHeitiSC-Medium;
    font-size: 16px;
    color: #ADADAD;
    text-align: center;
    line-height: 80px;

    @media screen and (max-width:1198px) {
        font-family: STHeitiSC-Medium;
        font-size: 12px;
        color: #ADADAD;
        text-align: center;
        height: 40px;
        line-height: 40px;
    }
}
</style>
